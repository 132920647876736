import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="inline-form"
export default class extends Controller {
  static targets = ["form", "select", "status"];

  connect() {
    var self = this;
    setTimeout(function() {
      if (self.selectTarget.className.includes("inline-select2")) {
        if ($(self.selectTarget).hasClass('no-search')) {
          $(self.selectTarget).select2({
            minimumResultsForSearch: Infinity,
            dropdownAutoWidth: true
          });
        } else {
          $(self.selectTarget).select2({
            dropdownAutoWidth: true
          });
        }
        $(self.selectTarget).on("select2:select", self.submit.bind(self));
      }
    }, 100)
    
  }

  disconnect() {
    console.log('inline form controller disconnected');
    try {
      $(this.selectTarget).off("select2:select", this.submit.bind(this));
      $(this.selectTarget).select2("destroy");
    } catch (error) {
      // console.log(error);
    }
  }

  update_dropdown(event) {
    const assigneeId = document.querySelector("#ticket_assignee_id").value;
    const dropdown = document.getElementById("assignee_dropdown");
    dropdown.value = assigneeId;
  }

  set_status_click(event) {
    console.log('set status click')
    const statusField = document.getElementById("status_field");
    console.log(statusField);
    this.update_status_display("open");
    $(this.formTarget).find('input[type="submit"]').click();
  }

  set_status_select(event) {
    event.preventDefault();
    console.log('set status select')
    const dropdown = this.selectTarget;
    const statusField = document.getElementById("status_field");

    if (dropdown.value === "") {
      statusField.value = "unread";
      this.update_status_display("unread");
    } else {
      statusField.value = "open"; 
      this.update_status_display("open");
    }
    $(this.formTarget).find('input[type="submit"]').click();
  }

  update_status_display(newStatus) {
    const statusDisplay = document.getElementById("status_display");
    if (statusDisplay) {
      const statusContent = this.get_status_content(newStatus);
      statusDisplay.innerHTML = "<i class='" + statusContent.icon + " " + statusContent.color + "'></i> " + statusContent.title + "<i class='la la-caret-down'></i>";
    }
  }

  get_status_content(statusKey) {
    const statusMap = {
      "unread": { title: "Unread", icon: "fa fa-envelope", color: "m--font-brand" },
      "open": { title: "Open", icon: "fa fa-envelope-open", color: "m--font-info" },
      "pending": { title: "Pending", icon: "fa fa-clock-o", color: "m--font-info" },
      "solved": { title: "Solved", icon: "fa fa-check", color: "m--font-success" }
    };
    return statusMap[statusKey] || statusMap["unread"];
  }

  submit() {
    $(this.formTarget).find('.select2-container .select2-selection__arrow').replaceWith('<div class="inline-anim-container pull-right"><div class="anim"><span></span> <span></span> <span></span></div></div>');
    $(this.formTarget).find('input[type="submit"]').click();
  }
}
